<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

import SaveButton from "@/components/buttons/save-button";
import CancelButton from "@/components/buttons/cancel-button";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SelectGroup,
        SaveButton,
        CancelButton
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `${modeName} Subject`,
            modeName,
            items: [
                {
                    text: "Subject"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                name: '',
                status: 1
            },
            statusOptions: [
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            serviceOptions: [],
            selectedServices: [],
            errors: {},
        };
    },
    async mounted() {
        const services = await this.retrieveServices();
        console.log('services mounted', services)
        this.serviceOptions = services.map(service => ({
            name: service.name,
            value: service.id,
        }));

        console.log('this.serviceOptions', this.serviceOptions)

        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject/${this.id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['subject'];
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    } else if (key === 'services') {
                        for (var itemKey in item) {
                            var obj = item[itemKey];
                            this.selectedServices.push(obj.id);
                        }
                    }
                }
            }
        }
    },
    methods: {
        toggleServiceCheckbox(serviceId) {
            const index = this.selectedServices.indexOf(serviceId);
            if (index !== -1) {
                this.selectedServices.splice(index, 1);
            } else {
                this.selectedServices.push(serviceId);
            }
        },
        async retrieveServices() {
            this.loading = true;
            let services = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const serviceDetails = result['data'].services;
                    console.log('serviceDetails', serviceDetails)
                    services = serviceDetails.filter(service => service.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return services;
        },

        async save() {
            this.errors = {};
            let result;

            const requestData = this.data;
            requestData.services = this.selectedServices;

            if (this.modeName === 'Add') {
                result = await axios.post(`${process.env.VUE_APP_APIURL}/field_management/subject`, requestData, {
                    headers: authHeader(),
                });
            } else {
                result = await axios.put(`${process.env.VUE_APP_APIURL}/field_management/subject/${this.id}`, requestData, {
                    headers: authHeader(),
                });
            }

            if (result) {
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.$router.back();
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                    }
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                }
            }
        },
        cancel() {
            this.$router.push({ name: "Subject" });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="row mt-2">
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="name" displayName="Subject" v-model="data.name" :error="errors.name" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <SelectGroup id="status" displayName="Status" v-model="data.status" :error="errors.status" :options="statusOptions" />
                            </div>

                            <div class="col-12">
                                <label class="label">Service</label>
                            </div>
                            <div v-for="service in serviceOptions" :key="service.value" class="col-12 col-md-3">
                                <input 
                                type="checkbox" 
                                :id="`service_${service.value}`" 
                                :value="service.value" 
                                :checked="selectedServices ? selectedServices.includes(service.value) : false" 
                                @change="toggleServiceCheckbox(service.value)">
                                &nbsp;<label :for="`service_${service.value}`">{{ service.name }}</label>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="row mt-2" style="float: right;">
                            <div class="col-12">
                                <CancelButton @click="cancel"></CancelButton>
                                <SaveButton :isSubmit="true" :text="'Save'" @click="save" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>