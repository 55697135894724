<script>
export default {
    props: {
        
    },

    data() {
        return {

        };
    },

    created() {

    },

    methods: {

    }
};
</script>

<template>
    <a class="cancel__btn btn mb-2" @click="$emit('click')">
        Cancel
    </a>
</template>