<script>
export default {
    props: {
        text: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {

        };
    },

    created() {

    },

    methods: {

    }
};
</script>

<template>
    <a class="submit__btn btn mb-2" @click="$emit('click')" :class="{ 'disabled': disabled }">
        {{ text ?? 'Save'}}
    </a>
</template>